.groundwire-setup {
	max-width: 440px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.groundwire-setup li h3 {
	font-size: 14pt;
	font-weight: 600;
}

.groundwire-screenshot {
	max-width: 100%;
	box-sizing: border-box;
}