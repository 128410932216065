body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a, .btn-link {
	&:hover {
		text-decoration: underline;
	}
}

$header-height: 60px;
$header-font-size: 16pt;
$header-font-weight: 600;

$transition-duration: 0.45s;
$transition-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);

#siteTitle {
	cursor: pointer;
}

.App {
	position: fixed;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	overflow: hidden;

	.AppToolbar {
		position: absolute;
		top: 0px;
		height: $header-height;
		left: 0px;
		right: 0px;
		display: flex;

		border-bottom-width: 1px;
		border-bottom-style: solid;

		.left {
			font-weight: $header-font-weight;
			font-size: $header-font-size;
			padding-left: 20px;
		}

		a {
			display: inline-block;
			padding: 4px 8px;
			box-sizing: border-box;
			margin: 8px;
			border-radius: 6px;
			border-width: 2px;
			border-style: solid;

			&.active {
				font-weight: 500;
			}

			&:hover {
				text-decoration: none;
			}

			&.small-only {
				display: none;
			}
		}
	}

	.AppBody {
		position: absolute;
		top: $header-height;
		bottom: 0px;
		left: 0px;
		right: 0px;
	}
}

.SplitView {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	overflow: hidden;

	@media (prefers-reduced-motion: no-preference) {
		.SplitViewListContainer, .SplitViewMain, .SplitViewList {
			transition-property: left, width, filter, box-shadow;
			transition-duration: $transition-duration;
			transition-timing-function: $transition-function;
		}
	}

	$sidebar-border-width: 1px;
	$sidebar-width: 300px;

	.SplitViewListContainer {
		position: absolute;
		left: 0px;
		top: 0px;
		width: $sidebar-width;
		bottom: 0px;
		z-index: 1;
		border-right-width: $sidebar-border-width;
		border-right-style: solid;
		overflow: hidden;
	}

	.SplitViewMain {
		position: absolute;
		left: $sidebar-width;
		top: 0px;
		right: 0px;
		bottom: 0px;
		z-index: 2;

		.ScrollTitle .backButton {
			display: none;
		}
	}

	.SplitViewList {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		bottom: 0px;

		&.blurred {
			left: -25%;
			filter: blur(3px);
		}

		&.focused {
			left: 0%;
			filter: none;
		}

		&.ready {
			left: 100%;
			filter: none;
		}
	}

	@media (max-width: 768px) {
		.SplitViewListContainer {
			width: 100%;
			border-right-width: 0px;
		}

		.SplitViewMain {
			left: 100%;
			width: 100%;
		}

		&.MainViewVisible {
			.SplitViewListContainer {
				left: -25%;
				filter: blur(3px);
			}

			.SplitViewMain {
				left: 0%;
				box-shadow: -4px 0px 4px scale-color(#000000, $alpha: -30%);

				.ScrollTitle .backButton {
					display: flex;
				}
			}
		}
	}
}
	
.containerWrapper {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	overflow: hidden;
	
	@media (prefers-reduced-motion: no-preference) {
		.listView, .detailView {
			transition-property: left, width, filter, box-shadow;
			transition-duration: $transition-duration;
			transition-timing-function: $transition-function;
		}
	}
	
	.listView {
		position: absolute;
		top: $header-height;
		left: 0px;
		bottom: 0px;
		width: 100%;
		border-right: 1px solid rgba(255, 255, 255, 0);
		z-index: 1;
	}
	
	.detailView {
		position: absolute;
		top: $header-height;
		left: 100%;
		bottom: 0px;
		width: 100%;
		box-shadow: none;
		z-index: 2;
		
		.bodyView {
			padding: 20px;
			text-align: center;
		}
	}
	
	.bodyView {
		position: absolute;
		top: $header-height;
		left: 0px;
		right: 0px;
		bottom: 0px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	
	@media (max-width: 768px) {
		.listView {
			border: none;
		}

		&.detailVisible {
			.listView {
				filter: blur(3px);
				left: -50%;
			}
			
			.detailView {
				left: 0px;
				box-shadow: -4px 0px 4px scale-color(#000000, $alpha: -30%);
			}
		}
	}
	
	@media (min-width: 768px) {
		.listView {
			width: 40%;
		}
		
		.detailView {
			left: 40%;
			width: 60%;
		}
	}
	
	@media (min-width: 1000px) {
		.listView {
			width: 30%;
		}
		
		.detailView {
			left: 30%;
			width: 70%;
		}
	}
	
	@media (min-width: 1200px) {
		.listView {
			width: 25%;
		}
		
		.detailView {
			left: 25%;
			width: 75%;
		}
	}
	
	@media (min-width: 1500px) {
		.listView {
			width: 20%;
		}
		
		.detailView {
			left: 20%;
			width: 80%;
		}
	}
	
	@media (min-width: 2000px) {
		.listView {
			width: 15%;
		}
		
		.detailView {
			left: 15%;
			width: 85%;
		}
	}
}

.headerView {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	height: $header-height;
	z-index: 20;
	border-bottom: 1px solid rgba(255, 255, 255, 0);

	.middle {
		font-weight: bold;
		font-size: 16pt;
	}
}

.scrollable{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	overflow: scroll;
}

.headerView+.scrollable {
	top: $header-height;
}

.overlayOuter {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(5px);

	.overlayInner {
		position: absolute;
		top: 50%;
		width: 600px;
		left: 50%;
		height: 80%;
		transform: translate(-50%, -50%);
		overflow: scroll;
		border-radius: 8px;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);

		@media (max-width: 640px) {
			top: 0px;
			left: 0px;
			width: auto;
			right: 0px;
			bottom: 0px;
			height: auto;
			border-radius: 0px;
			transform: none;
			box-shadow: none;
		}
	}
}

.voiceListItem {
	display: flex;
}

.callerName {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: auto;
	width: 50%;
	text-align: left;
}

.voicemailAge {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: auto;
	width: 50%;
	text-align: right;
}

.detailHeader {
	border-bottom: 1px solid rgba(255, 255, 255, 0);
	padding: 5pt;
	height: $header-height;
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	z-index: 10;
	background-color: rgba(255, 255, 255, 0);
	backdrop-filter: blur(5px);
	
	&.triple {
		.middle {
			font-weight: bold;

			span.date {
				font-size: smaller;
				font-weight: normal;
				opacity: 0.7;
			}
		}
	}

	.form-group {
		margin: 0px;
	}

	.custom-select {
		margin: 0px;
		background-color: rgba(255, 255, 255, 0);
		text-align: center;
		border: none;
	}
}

.triple {
	display: flex;
	align-items: center;
	
	.left, .middle, .right {
		flex: 1 1 0;
	}
	
	.left {
		text-align: left;
	}
	
	.middle {
		text-align: center;
	}
	
	.right {
		text-align: right;
	}
}

.small-only {
	display: none;
}

.large-only {
	display: initial;
}

.loginWrapper {
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	padding: 20px;
	max-width: 800px;
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;

	.loginLogoSpace {
		flex: 0 0 0;
		font-weight: bold;
		text-align: center;
		font-size: x-large;
		margin-bottom: 30px;
		margin-top: 15%;
	}

	.loginForm {
		flex: 0 1 0;
		border: 1px solid rgba(255, 255, 255, 0);
		padding: 20px;
		border-radius: 6px;
	}
}

.voicemailDetail {
	text-align: center;
	padding: 14px;
}

.transcript.missing {
	font-style: italic;
}


.ScrollWrapper {
	$scroll-header-height: 60px;

	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	overflow: hidden;

	.ScrollTitle {
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		height: $scroll-header-height;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		align-items: center;
		justify-content: center;

		.middle {
			font-size: $header-font-size;
			font-weight: $header-font-weight;
			white-space: nowrap;
			line-height: 1.0em;

			span.multiline {
				font-size: $header-font-size * 0.9;

				span.sub {
					font-size: $header-font-size * 0.7;
					font-weight: normal;
					opacity: 0.7;
				}
			}
		}
	}

	.ScrollView {
		position: absolute;
		top: $scroll-header-height;
		bottom: 0px;
		left: 0px;
		right: 0px;
		overflow-y: scroll;

		.NoContentView {
			text-align: center;
			padding: 20px;
		}
	}

	&.ScrollHeaderMobile, &.ScrollHeaderNever {
		.ScrollTitle {
			display: none;
		}

		.ScrollView {
			top: 0px;
		}
	}

	@media (max-width: 768px) {
		&, &.ScrollHeaderMobile {
			.ScrollTitle {
				display: flex;
			}
	
			.ScrollView {
				top: $scroll-header-height;
			}
		}
	}
}

#voicemailMailboxSelector {
	margin: 0px;

	select {
		border-width: 0px;
		border-radius: 0px;
	}
}

.backButton {
	display: flex;
	align-items: center;

	&.small-only {
		display: none;
	}

	.backWidget {
		flex: 0 0 auto;
		margin-right: 10%;

		svg {
			width: 8pt;
			height: 12pt;
			margin: 0px;
			display: block;
		}
	}

	.backText {
		flex: 1 1 auto;
	}
}

.list-group-item {
	cursor: pointer;
}

@media (max-width: 768px) {
	.containerWrapper {
		overflow: hidden;
	}
	
	.listView {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		border: none;
	}
	
	.detailView {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		left: 100%;
		right: auto;
	}
	
	.small-only {
		display: initial;
	}
	
	.large-only {
		display: none;
	}

	.App {
		.AppToolbar {
			top: auto;
			bottom: 0px;
			border-top-width: 1px;
			border-top-style: solid;
			border-bottom-width: 0px;

			.right {
				text-align: center;
			}

			.left, .middle {
				display: none;
			}

			a.small-only {
				display: inline-block;
			}
		}

		.AppBody {
			top: 0px;
			bottom: $header-height;
		}
	}

	.list-group-item {
		a:hover {
			text-decoration: none;
		}
	}

	.backButton.small-only {
		display: flex;
	}
}

.loadingContainer {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	text-align: center;
	display: flex;
	flex-direction: row;
	align-items: center;

	.loadingContent {
		margin-top: -30%;
		flex: 1 1 0;
	}
}

@media (prefers-reduced-motion: no-preference) {
	.list-group-item {
		transition-property: background-color, color, border-color;
		transition-duration: $transition-duration;
		transition-timing-function: $transition-function;
	}
}

@mixin buildColorStyles($font-color, $link-color, $background-color, $danger-color) {
	$border-color: mix($background-color, $font-color, 85%);
	
	body {
		background-color: $background-color;
		color: $font-color;
	}
	
	a, .btn-link {
		color: $link-color;

		&:hover {
			color: $link-color;
		}

		&:visited {
			color: $link-color;
		}

		&:active {
			color: darken($link-color, 50%);
		}

		&.danger {
			color: $danger-color;

			&:hover {
				color: $danger-color;
			}
	
			&:visited {
				color: $danger-color;
			}

			&:active {
				color: darken($danger-color, 50%);
			}
		}
	}

	.App {
		.AppToolbar {
			border-color: $border-color;

			a {
				color: $link-color;
				border-color: $background-color;

				&.active {
					color: $background-color;
					background-color: $link-color;
					border-color: $link-color;
				}

				&:not(.active):hover {
					color: $link-color;
					background-color: $background-color;
					border-color: $link-color;
				}

				&:not(.active):active {
					$pressed-color: scale-color($link-color, $lightness: -25%);
					color: $pressed-color;
					border-color: $pressed-color;
					background-color: scale-color($background-color, $lightness: -25%);
				}
			}
		}
	}

	.ScrollWrapper {
		.ScrollTitle {
			border-color: $border-color;
		}
	}

	.SplitView {
		.SplitViewListContainer {
			border-color: $border-color;
		}

		.SplitViewListContainer, .SplitViewMain, .SplitViewList {
			background-color: $background-color;
		}
	}

	.containerWrapper {
		color: $font-color;

		.listView {
			border-color: $border-color;
			background-color: $background-color;
			
			.list-group-item {
				background-color: $background-color;
				border-color: scale-color($border-color, $alpha: -50%);
			}
		}

		.detailView {
			background-color: $background-color;
		}
	}

	.headerView {
		border-color: $border-color;
		background-color: $background-color;
	}

	.detailHeader {
		border-color: $border-color;
		background-color: scale-color($background-color, $alpha: -20%);
		
		.custom-select {
			color: $font-color;
			background: rgba(255, 255, 255, 0) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='#{$font-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
		}

		.btn-outline-primary {
			color: $link-color;
			border: none;
		}
	}
	
	.loginWrapper {
		.loginForm {
			border-color: $border-color;
		}
	}

	.modal-content {
		background-color: $background-color;
		border-color: $border-color;
		color: $font-color;

		.modal-footer {
			border-color: $border-color;
		}
	}

	.overlayOuter {
		.overlayInner {
			background-color: scale-color($background-color, $alpha: -5%);
		}
	}

	.list-group-item {
		background-color: transparent;
		border-color: $border-color;
		color: $link-color;
		
		a {
			color: $link-color;
		}
	}

	.backButton .backWidget svg {
		fill: $link-color;
	}

	@media (min-width: 769px) {
		.list-group-item {
			&.active {
				background-color: $link-color;
				color: $background-color;
				border-color: $border-color;

				a {
					color: $background-color;
				}
			}
		}
	}

	@media (max-width: 768px) {
		.list-group-item {
			&.active {
				background-color: transparent;
				color: $link-color;
				border-color: $border-color;

				a {
					color: $link-color;
				}
			}
		}
	}
}

@include buildColorStyles(#000000, #337eda, #ffffff, #ef6149);

@media (prefers-color-scheme: dark) {
	@include buildColorStyles(#ffffff, #67acf8, #232323, #ef6943);

	.spinner-border, .spinner-grow {
		&.text-primary {
			color: #ffffff !important;
		}
	}
}